/* FONT PATH
 * -------------------------- */

@font-face {
  font-family: 'FontAwesome';
  src: url('#{$fa-font-path}#{$fa-font-name}.eot?v=#{$fa-version}');
  src: url('#{$fa-font-path}#{$fa-font-name}.eot?v=#{$fa-version}#iefix') format('embedded-opentype'),
    url('#{$fa-font-path}#{$fa-font-name}.woff2?v=#{$fa-version}') format('woff2'),
    url('#{$fa-font-path}#{$fa-font-name}.woff?v=#{$fa-version}') format('woff'),
    url('#{$fa-font-path}#{$fa-font-name}.ttf?v=#{$fa-version}') format('truetype'),
    url('#{$fa-font-path}#{$fa-font-name}.svg?v=#{$fa-version}#fontawesomeregular') format('svg');
  font-weight: normal;
  font-style: normal;
}
