// UTILITY MIXINS
// --------------------------------------------------

// For clearing floats like a boss h5bp.com/q
@mixin clearfix {
  *zoom: 1;
  &:before,
  &:after {
    display: table;
    content: "";
    // Fixes Opera/contenteditable bug:
    // http://nicolasgallagher.com/micro-clearfix-hack/#comment-36952
    line-height: 0;
  }
  &:after {
    clear: both;
  }
}

// Center-align a block level element
// ----------------------------------
@mixin center-block() {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

// ROUND CORNERS
// --------------------------------------------------

// .border-radius(VALUE,VALUE,VALUE,VALUE);
@mixin border-radius($topright: 0, $bottomright: 0, $bottomleft: 0, $topleft: 0) {
  -webkit-border-top-right-radius : $topright;
  -webkit-border-bottom-right-radius : $bottomright;
  -webkit-border-bottom-left-radius : $bottomleft;
  -webkit-border-top-left-radius : $topleft;
  -moz-border-radius-topright : $topright;
  -moz-border-radius-bottomright : $bottomright;
  -moz-border-radius-bottomleft : $bottomleft;
  -moz-border-radius-topleft : $topleft;
  border-top-right-radius : $topright;
  border-bottom-right-radius : $bottomright;
  border-bottom-left-radius : $bottomleft;
  border-top-left-radius : $topleft;
  -webkit-background-clip : padding-box;
  -moz-background-clip : padding;  
  background-clip : padding-box; 
}

// .rounded(VALUE);
@mixin rounded($radius:4px) {
  -webkit-border-radius : $radius;
  -moz-border-radius : $radius;
  border-radius : $radius; 
}

// TYPOGRAPHY
// --------------------------------------------------

// Full-fat vertical rhythm
// ------------------------
@mixin font-size($size) {
  font-size: 0px + $size;
  font-size: 0rem + $size / $doc-font-size;
  line-height: 0 + round($doc-line-height / $size*10000) / 10000;
  margin-bottom: 0px + $doc-line-height;
  margin-bottom: 0rem + ($doc-line-height / $doc-font-size);
}

// Just the REMs
// -------------
@mixin font-rem($size) {
  font-size: 0px + $size;
  font-size: 0rem + $size / $doc-font-size;
}

// Just font-size and line-height
// ------------------------------
@mixin font($size) {
  font-size: 0px + $size;
  font-size: 0rem + $size / $doc-font-size;
  line-height: 0 + round($doc-line-height / $size*10000) / 10000;
}

// GRADIENTS
// --------------------------------------------------

@mixin horizontal($startColor : $color_gallery, $midColor :  $color_stack, $endColor : $color_gallery) {
  background-color: $endColor;
  background-image  : -webkit-linear-gradient(left, $startColor, $midColor, $endColor); 			 // Safari 5.1+, Chrome 10+
  background-image  : -moz-linear-gradient(left, $startColor, $midColor, $endColor); 					 // FF 3.6+
  background-image  : -ms-linear-gradient(left, $startColor, $midColor, $endColor); 					 // IE10
  background-image  : -o-linear-gradient(left, $startColor, $midColor, $endColor); 						 // Opera 11.10
  background-image  : linear-gradient(left, $startColor, $midColor, $endColor); 							 // W3C
  background-repeat : repeat-x; 
}

// TRANSFORMATIONS
// --------------------------------------------------

// .transition(PROPERTY DURATION DELAY(OPTIONAL) TIMING-FINCTION);
@mixin transition($transition) {
  -webkit-transition : $transition;
  -moz-transition : $transition;
  -ms-transition : $transition;
  -o-transition : $transition;
  transition : $transition; 
}
