@media print {
  body {
    background-image: none !important;
    background-color: $white;
  }
  .wrapper {
    box-shadow: none;
    width: 100%;
    max-width: 100%;
    margin: 0 auto;
  }
  .container {
    box-shadow: none;
  }
  .feature {
      padding: 0;
  }
  .social-btn, .btn {
    border-color: $color_mischka;
  }
  .post-title .btn,
  .reading-time,
  #dl-menu,
  #disqus_thread,
  .goup-container {
    display: none;
  }
}
